import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import { Form, Input, SubmitButton } from "formik-antd";
import { message } from "antd";
import * as Yup from "yup";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
export default function PassReset() {
  const [verifiedCap, setVerifiedCap] = useState(false);
  const [buttonState, setButtonState] = useState(true);
  const [requestWaitTime, setRequestWaitTime] = useState(false);
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email("Email not valid")
      .required("Email is required"),
  });

  function captchaVerify(value) {
    value !== null ? setVerifiedCap(true) : setVerifiedCap(false);
  }

  useEffect(() => {
    verifiedCap === true && requestWaitTime === false
      ? setButtonState(false)
      : setButtonState(true);
  }, [verifiedCap, requestWaitTime]);

  useEffect(() => {
    if (requestWaitTime === true) {
      setTimeout(function () {
        setButtonState(true);
      }, 5000);
      setButtonState(false);
      setRequestWaitTime(false);
    }
  }, [requestWaitTime]);

  const resetOptions = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/resetpasswordrequest`,
    data: { useremail: "" },
  };

  async function resetPass(username) {
    resetOptions.data.useremail = username;
    resetOptions.headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      "Content-Type": "application/json",
    };

    try {
      const resetResp = await axios.request(resetOptions);
      return {
        msg: resetResp.data["request_msg"],
        status: resetResp.status,
      };
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const submitForm = (values) => {
    let messageReturned = values.username;
    setButtonState(true);
    resetPass(messageReturned).then((resetResp) => {
      resetResp
        ? message.success(
            "A message has been sent to you by email with instructions on how to reset your password."
          )
        : message.error(
            "An Error Occured with the Password Reset Server. Please contact us."
          );
    });
    setTimeout(function () {
      setButtonState(false);
    }, 10000);
    return true;
  };

  return (
    <div id="bckgDiv">
      <div className="flex-container">
        <div id="cardContent">
          <h1 className="titleText">Password Reset</h1>
          <p>
            Please enter your <b>Coras username</b> or the <b>email address</b> that
            you use to login to Coras below.
            <br />
            <br />
            You'll then receive a link to reset your password.
          </p>
          <Formik
            onSubmit={(values) => submitForm(values)}
            initialValues={{ username: "" }}
            validationSchema={validationSchema}
          >
            <Form>
              {/* <p
                style={{
                  color: "#001864",
                  paddingTop: "3vh",
                  fontFamily:
                    "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
                }}
              >
               
              </p> */}
              <Form.Item name="username">
                <Input
                  name="username"
                  placeholder="example@corpcloud.com.au"
                  style={{ borderColor: "#001864" }}
                />
              </Form.Item>
              <ReCAPTCHA
                sitekey="6LfYD0EeAAAAAMwSA862mWvEW7RYhEMeE3cmFCQg"
                style={{ paddingTop: "5vh" }}
                onChange={(value) => captchaVerify(value)}
              />
              <Form.Item name="controls" style={{ paddingTop: "5vh" }}>
                <SubmitButton
                  disabled={buttonState}
                  style={{
                    color: "#001864",
                    backgroundColor: "#FFC20E",
                    borderColor: "#001864",
                  }}
                >
                  Submit
                </SubmitButton>
              </Form.Item>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}
