import React from "react";
import axios from "axios"; 
import { useState,useEffect } from "react";
import { DownloadOutlined } from '@ant-design/icons';
import { Button,Space } from "antd";

export default function Guides() {
    const [guideList, setGuideList] = useState()
    useEffect(() => {
        if (!guideList) {
            const options = {
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/listguides`,
                headers: {
                  'Content-Type': 'application/json',
                  'X-Api-Key': process.env.REACT_APP_API_KEY
                }
                };

                axios.request(options).then(function (response) {
                    var guidesarray = []
                    Object.entries(response.data).forEach(([key]) => {
                        guidesarray.push({guidename: key, guideurl: "https://coras-user-guides.s3.ap-southeast-2.amazonaws.com/" + key});
                    });
                    setGuideList(guidesarray)
                }).catch(function (error) {
                console.error(error);
                });
            }
    });

    return (
      <div id="bckgDiv">
        <br />
        <br />
         <div id="cardContentGuides">
          <h1
            style={{
              fontSize: "3em",
              fontWeight: "bold",
              fontFamily:
                "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
                textAlign: "centre",
            }}
          >
            User Guides
          </h1>
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: 'flex',
            }}
          >
            {guideList ? BuildGuideList(guideList) : <div />}
          </Space>
        </div>
      </div>
         );
}

function BuildGuideList(data) {
    const map1 = data.map((x, index, array) => (
        <Button
          key={data[index].guidename}
          icon={<DownloadOutlined />}
          size="large"
          href={data[index].guideurl}
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#001864",
            borderColor: "#001864",
            backgroundColor: "#FFC20E",
            borderRadius: "6px",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          }}
        >{data[index].guidename}
        </Button>
    ));
    return map1
}